import * as React from 'react';
import { useState } from 'react';
import TreeView from '@mui/lab/TreeView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TreeItem from '@mui/lab/TreeItem';

FileSystemNavigator.propTypes = {};

function FileSystemNavigator() {
  const [data, setData] = useState(
    {
      nodeId: 0, label: "Root", children: [
        {
          nodeId: 1, label: "Folder 1", isDirectory: true, children: [
            {
              nodeId: 2, label: "Folder 1.1", isDirectory: true, children: [
                { nodeId: 4, label: "aaa.txt", isRegularFile: true }
              ]
            },
            {
              nodeId: 3, label: "Folder 1.2", isDirectory: true, children: [
                { nodeId: 7, label: "bbb.txt", isRegularFile: true }
              ]
            }
          ]
        },
        {
          nodeId: 5, label: "Folder 2", isDirectory: true, children: [
            { nodeId: 10, label: "Folder 2.1" },
            {
              nodeId: 6, label: "Folder 2.2", isDirectory: true, children: [
                {
                  nodeId: 8, label: "index.js", isRegularFile: true
                }
              ]
            },
          ]
        }
      ]
    });

  const renderTree = (node) => {
    return (
      <TreeItem key={node.nodeId} nodeId={node.nodeId} label={node.label}>
        {Array.isArray(node.children)
          ? node.children.map((child) => renderTree(child))
          : null}
      </TreeItem>
    );
  };

  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpanded={[0]}
      defaultExpandIcon={<ChevronRightIcon />}
      sx={{ height: 240, flexGrow: 1, maxWidth: 400, overflowY: 'auto' }}
    >
      {
        renderTree(data)
      }
    </TreeView>
  );
}

export default FileSystemNavigator;