import './App.css';

import FileSystemNavigator from './components/FileSystemNavigator';

function App() {
  return (
    <div className="App">
      <FileSystemNavigator />
    </div>
  );
}

export default App;
